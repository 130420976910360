<template>
  <div class="text-left LoginNew" style="overflow-x:hidden;">
    <div v-if="isLoading">
      <LoaderComp/>
    </div>
    <div v-else class="row h-100">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div class="">
    
          <div class="px-5">
            <img
              src="assets\img\new-website\LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell"
              class="img-fluid"
              @click="openWebsite"
            />
          </div>

          <div class="TitleDiv">
            <h1 class="title">Set Up your account</h1>
          </div>

          <!-- Inputs -->
          <div class="px-2 px-sm-5">
            <div class="new-form-element px-0 px-sm-5">
              <h2
                class="m-0 pl-1 h2"
              >
                Account Email <span style="color: red">*</span>
              </h2>
              <div class="NewFormInput d-flex align-items-center w-100 py-2">
                <input
                  type="email"
                  class="mx-2 flex-grow-1"
                  placeholder="Enter your account email"
                  v-model="userDetail.accountEmail"
                />
              </div>
            </div>
            <div class="new-form-element mt-3 px-0 px-sm-5">
              <p class="m-0 pl-1">
                Business name <span style="color: red">*</span>
              </p>
              <div class="NewFormInput d-flex align-items-center w-100 py-2">
                <input
                  type="text"
                  class="mx-2 flex-grow-1"
                  placeholder="Enter your Business name"
                  v-model="userDetail.businessName"
                />
              </div>
            </div>
            <div class="new-form-element mt-3 px-0 px-sm-5">
              <p class="m-0 pl-1">
                Create a password <span style="color: red">*</span>
              </p>
              <div class="NewFormInput d-flex align-items-center w-100 py-2">
                <input
                  id="Sign-up-pass-input"
                  :type="passwordFieldType"
                  class="mx-2 flex-grow-1"
                  placeholder="Enter your password"
                  v-model="userDetail.password"
                />

                <div
                  style="cursor: pointer"
                  class="mr-2"
                  v-if="passwordFieldType == 'text'"
                >
                  <b-icon-eye style="color: #d1d1d1" @click="switchPass" />
                </div>
                <div
                  style="cursor: pointer"
                  class="mr-2"
                  v-else
                  @click="switchPass"
                >
                  <b-icon-eye-slash style="color: #d1d1d1" />
                </div>
              </div>

              <div>
                <b-tooltip
                  id="tooltipSignup"
                  target="Sign-up-pass-input"
                  ref="passTooltip"
                  triggers="focus"
                  placement="topleft"
                >
                  <div class="row" style="font-size: 14px">
                    <div class="col-12">
                      <div class="d-flex align-items-center">
                        <div style="flex: 0.1" class="mr-1">
                          <div v-if="isLengthValid" class="text-success">
                            <b-icon-check-circle-fill
                              class=""
                            ></b-icon-check-circle-fill>
                          </div>
                          <div v-else class="text-danger">
                            <b-icon-x-circle-fill
                              class=""
                            ></b-icon-x-circle-fill>
                          </div>
                        </div>
                        <div class="" style="flex: 0.9; text-align: start">
                          <div v-if="isLengthValid" class="text-success">
                            Eight character long.
                          </div>
                          <div v-else class="text-danger">
                            Eight character long.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-center">
                        <div style="flex: 0.1" class="mr-1">
                          <div v-if="isLowercaseValid" class="text-success">
                            <b-icon-check-circle-fill
                              class=""
                            ></b-icon-check-circle-fill>
                          </div>
                          <div v-else class="text-danger">
                            <b-icon-x-circle-fill
                              class=""
                            ></b-icon-x-circle-fill>
                          </div>
                        </div>
                        <div class="" style="flex: 0.9; text-align: start">
                          <div v-if="isLowercaseValid" class="text-success">
                            One lowercase letter
                          </div>
                          <div v-else class="text-danger">
                            One lowercase letter
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-center">
                        <div style="flex: 0.1" class="mr-1">
                          <div v-if="isUppercaseValid" class="text-success">
                            <b-icon-check-circle-fill
                              class=""
                            ></b-icon-check-circle-fill>
                          </div>
                          <div v-else class="text-danger">
                            <b-icon-x-circle-fill
                              class=""
                            ></b-icon-x-circle-fill>
                          </div>
                        </div>
                        <div class="" style="flex: 0.9; text-align: start">
                          <div v-if="isUppercaseValid" class="text-success">
                            One uppercase letter.
                          </div>
                          <div v-else class="text-danger">
                            One uppercase letter.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-center">
                        <div style="flex: 0.1" class="mr-1">
                          <div v-if="isNumberValid" class="text-success">
                            <b-icon-check-circle-fill
                              class=""
                            ></b-icon-check-circle-fill>
                          </div>
                          <div v-else class="text-danger">
                            <b-icon-x-circle-fill
                              class=""
                            ></b-icon-x-circle-fill>
                          </div>
                        </div>
                        <div class="" style="flex: 0.9; text-align: start">
                          <div v-if="isNumberValid" class="text-success">
                            One number.
                          </div>
                          <div v-else class="text-danger">One number.</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-center">
                        <div style="flex: 0.1" class="mr-1">
                          <div
                            v-if="isSpecialCharacterValid"
                            class="text-success"
                          >
                            <b-icon-check-circle-fill
                              class=""
                            ></b-icon-check-circle-fill>
                          </div>
                          <div v-else class="text-danger">
                            <b-icon-x-circle-fill
                              class=""
                            ></b-icon-x-circle-fill>
                          </div>
                        </div>
                        <div class="" style="flex: 0.9; text-align: start">
                          <div
                            v-if="isSpecialCharacterValid"
                            class="text-success"
                          >
                            One special character.
                          </div>
                          <div v-else class="text-danger">
                            One special character.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tooltip>
              </div>

              <div class="m-0 pl-1 mt-1" v-if="userDetail.password != ''">
                <div class="text-danger" v-if="!isPasswordValid">
                  <span> Invalid Password</span>
                </div>
                <span class="text-success" v-else> Password is valid! </span>
              </div>
            </div>

            <div class="new-form-element mt-2 px-0 px-sm-5">
              <b-form-checkbox
                id="NewSignUpCheckbox"
                v-model="userDetail.agreeTerms"
                name="NewSignUpCheckbox"
                class="SignUpCheckbox"
                :value="true"
                :unchecked-value="false"
              >
                <p class="m-0">
                  I agree with the
                  <button class="btn m-0 p-0" @click.stop="openTC" style="color: rgb(77, 27, 126);">
                   <strong> Terms & Conditions</strong>
                  </button>
                </p>
              </b-form-checkbox>
            </div>

            <div class="mt-2 d-flex justify-content-center">
              <!-- <vue-recaptcha :sitekey="captchaSiteKey"></vue-recaptcha> -->
               <vue-recaptcha
      ref="recaptcha"
      :sitekey="captchaSiteKey"
      @verify="onVerify"
      @expired="onExpired"
      @error="onError"
      :loadRecaptchaScript="true"
    ></vue-recaptcha>
              <!-- <recaptcha /> -->
            </div>
            <div class="mt-3 px-5">
              <button
                :disabled="!isSignUpValid"
                @click="SignUp"
                class="btn w-100 NewFormInput-Btn px-3 py-2"
              >
                <span v-if="!SignUpSpinner">Sign Up</span>
                <div
                  v-else
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>

          <!-- REgister -->
          <div class="mt-3">
            <p class="text-center register">
              Already have an account?
              <router-link
                class="btn m-0 p-0"
                style=" color: #4d1b7e"
                to="/login"
              >
               <strong>Login</strong> </router-link
              >
            </p>

            <p class="text-center register">
              Forgot password?
              <button
                class="btn m-0 p-0"
                style="color: #4d1b7e"
                @click="$router.push('/password-reset')"
              >
               <strong> Reset Password</strong>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div class="d-none d-lg-block col-lg-5 h-100 objectSection">

        
<LazyImage
            src="/assets/img/LoginObject.png"
            :title="`Quizell login`"
            :alt="`Quizell login`"
            class="h-100 w-100"
          />
</div>
    </div>

    <signupModal ref="ConfirmSignUpRef" />
  </div>
</template> 



<script>

import signupModal from './signupModal.vue';
import axios from 'axios'
import LoaderComp from "../customize/component/LoaderComp.vue";
import { BIconEyeSlash, BIconEye } from "bootstrap-vue";
// import { VueRecaptcha } from 'vue-recaptcha';
import  VueRecaptcha  from 'vue-recaptcha';
export default {
  name: "Login",
  components: {
    BIconEyeSlash,
    BIconEye,
    LoaderComp,
    signupModal,
   VueRecaptcha
  },

  data() {
    return {
      
      userDetail: {
        accountEmail: "",
        businessName: "",
        password: "",
        agreeTerms: false,
      },
      passwordFieldType: "password",
      SignUpSpinner: false,
      isLoading: false,
      captchaSiteKey:  process.env.VUE_APP_CAPTCHA_SITE_KEY,
      captchaValue: null,
    }
  },
  created(){
    let user_access_token = localStorage.getItem("user_access_token") 
   if( user_access_token !== null){
          window.open("/","_self")
         }
  
},
methods: {

     onVerify(response) {
      this.captchaValue = response
      // Send the reCAPTCHA response to your server for validation
    },
    onExpired() {
      this.$refs.recaptcha.reset();
    },
    onError() {
      this.$refs.recaptcha.reset();
    },
    validateEmail(email) {
      /* eslint-disable-next-line */
      let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return pattern.test(email);
    },
    onOpen() {
      this.$refs.passTooltip.$emit("open");
    },
    onClose() {
      this.$refs.passTooltip.$emit("close");
    },
    switchPass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    openTC() {
     
        window.open(process.env.VUE_APP_MAIN_WEBSITE+"/terms-and-condition", "_blank");
      
    },
    async SignUp() {
      let isEmailValid = this.validateEmail(this.userDetail.accountEmail);
      if (isEmailValid) {
        this.SignUpSpinner = true;
        try {
          let data = {
            email: this.userDetail.accountEmail,
            password: this.userDetail.password,
            name: this.userDetail.businessName,
            "g-recaptcha-response": this.captchaValue,
          };
          const response = await axios.post(`/register`, data);
          // await this.$recaptcha.reset();
          if (response.status == 200 && response.data.status == "fail") {
            const Error = response.data.data;
            if (typeof Error == "object") {
              for (const key in Error) {
                this.$toasted.show(`${Error[key]}`, {
                  theme: "toasted-primary",
                  type: "error",
                  position: "bottom-center",
                  duration: 2000,
                });
              }
            } else if (typeof Error == "string") {
              this.$toasted.show(`${Error}`, {
                theme: "toasted-primary",
                type: "error",
                position: "bottom-center",
                duration: 2000,
              });
            } else {
              this.$toasted.show("Error occured", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 4000,
              });
            }
          } else {
            localStorage.setItem(
              "user_access_token",
              response.data.access_token
            );
            this.$router.push("/email-verify");
          }
        }  finally {
          this.SignUpSpinner = false;
           this.$refs.recaptcha.reset();
        }
      } else {
        this.$toasted.show("Email is not valid.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 4000,
        });
      }
    },
     openWebsite(){
      window.open(this.getMainAppUrl,"_self")
    },
  },
  computed: {
    getMainAppUrl()
        {
         
        return process.env.VUE_APP_MAIN_WEBSITE
        },
    isSignUpValid() {
      return (
        this.userDetail.accountEmail !== "" &&
        this.userDetail.password !== "" &&
        this.userDetail.businessName !== "" &&
        this.userDetail.agreeTerms &&
        this.isPasswordValid
      );
    },
    isPasswordValid() {
      const passwordRegex = new RegExp(
        /^(?=^.{8,}$)(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
      );
      return passwordRegex.test(this.userDetail.password) ? true : false;
    },
    isLengthValid() {
      const passwordRegex = new RegExp(/^(?=^.{8,}$).*$/);
      return passwordRegex.test(this.userDetail.password) ? true : false;
    },
    isUppercaseValid() {
      const passwordRegex = new RegExp(/^(?=.*?[A-Z]).*$/);
      return passwordRegex.test(this.userDetail.password) ? true : false;
    },
    isLowercaseValid() {
      const passwordRegex = new RegExp(/^(?=.*?[a-z]).*$/);
      return passwordRegex.test(this.userDetail.password) ? true : false;
    },
    isNumberValid() {
      const passwordRegex = new RegExp(/^(?=.*?[0-9]).*$/);
      return passwordRegex.test(this.userDetail.password) ? true : false;
    },
    isSpecialCharacterValid() {
      const passwordRegex = new RegExp(/^(?=.*?[#?!@$%^&*-]).*$/);
      return passwordRegex.test(this.userDetail.password) ? true : false;
    },
    userPass() {
      return this.userDetail.password;
    },
  },
  watch: {


    userPass() {
      if (this.isPasswordValid) {
        this.onClose();
      } else {
        this.onOpen();
      }
    },
  },
  mounted() {
    this.$refs.ConfirmSignUpRef.OpenModal();
  },
};
</script>
<style scoped>
.LoginNew {
  height: 100vh;
}
.h2{
font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;

                  color: #161e34;
}
.LoginNew .MainSection {
  padding: 2% 5%;
  background: #ffffff;
}
.MainSection .TitleDiv {
  /* padding: 48px; */
  padding: 30px 48px 20px 48px;
}
.MainSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #161e34;
}
.objectSection {
  /* background-image: url("~/assets/new-website/loginObject.png"); */
  background-color: #ffffff;
  height: 100%;
  /* background-repeat: no-repeat;
  background-size: 100% 100vh; */
}
.loginContainer{
  padding:0 5%;
}

.NewFormInput-Btn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.NewFormInput-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}
.NewFormInput-Btn:disabled:hover {
  background: #4d1b7e !important;
}
.NewFormInput {
        border: 1px solid #4D1B7E;
    border-radius: 10px;
        box-sizing: border-box;
        background:#ffffff;
    }
    
    .NewFormInput input {
        background: transparent;
        border: none;
        outline: none;
    }
    .NewFormInput input:focus {
        border: none;
        outline: none;
    }
    .NewFormInput input::placeholder {
       font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    text-align: start;
    
    color: #D1D1D1;
    }
    
    .NewFormInput-Btn{
        background: #4D1B7E;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    }
    .NewFormInput-Btn:hover{
        background: #FFC000;
    color: #FFFFFF;
    }

.MainSection .register {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #161e34;
}
.MainSection .register button {
  color: #4d1b7e;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
@media (max-width:600px){
  .loginContainer{
  padding:0;
}
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}
</style>
